import axios from 'axios';
import {printLogs,logtype} from './LogService';

export const CustomerApi = async (url) => {
    try{
        var response = await axios.get(url+"/getPage/sgh/customer");
        printLogs("Services->CustomerApi->response : ",logtype.Debug);
        printLogs(response,logtype.Debug);
        printLogs("Services->CustomerApi->Fetched successful",logtype.Info);
    }
    catch(error)
    {
        printLogs("Services->CustomerApi->Customer data unsuccessful -> Error",logtype.Error);
        printLogs(error,logtype.Error);
    }
    return response?.data;
}