import * as actions from './actions';
import {printLogs,logtype} from '../Services/LogService';

const initialstate={
    isauthenticated : false,
    token : null,
    username : null,
    home : null,
     url : "https://www.sasgroundhandling.com",
   // url: "https://uat.saspartnerportal.com:442"

}

const Authreducer = (state=initialstate,action) => {
    // console.log("action.type : ",action.type);
    
    switch(action.type)
    {
        case actions.Set_Data : 
        state={
            ...state,
            home:action.home
        }
        break;
    }
    // console.log("state : ",state);
    printLogs("redux state : ",logtype.Debug);
    printLogs(state,logtype.Debug);
    return state;
}


export default Authreducer;
