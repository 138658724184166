import React,{useEffect,useState} from 'react';
import classes from './Customer.module.css';
import TopSection from '../TopSection/TopSection';
import {useSelector} from 'react-redux';
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {Col,Row} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {CustomerApi} from '../../Services/CustomerService';

const Customer = (props) => {

    const options = {
        renderNode: {
           
        // [INLINES.EMBEDDED_ASSET_BLOCK]: (node, children) => {
        //     console.log("Image : ",node.data.target.fields.file.url)
        //     return (<div><img src={node.data.target.fields.file.url} alt="No Image found" /></div>)
                
        //   }

        // 'embedded-asset-block': (node) =>`<img class="img-fluid" src="${node.data.target.fields.file.url}"/>`

        [BLOCKS.EMBEDDED_ASSET]: (node, children) => { // render the EMBEDDED_ASSET as you need
         return ( <img src={`https://${node?.data?.target?.fields?.file?.url}`} className={classes.ourcustomerimg} alt={node?.data?.target?.fields?.description} /> ); 
        },
        },
       
      };

    const url = useSelector(state=>state.url);
    const [customer,setCustomer] = useState(null);
    const [links,setLinks] = useState(null);
    // console.log("operate : ",all);
    useEffect(async ()=>{
        let customerdata = await CustomerApi(url);
        // console.log("Customer data : ",customerdata);
        let linkarray = customerdata.links.map(info=>{
            return info.pageName;
        })
        linkarray.splice(1,0,customerdata.title);
        // console.log("operate props data : ",all?.Links[0]);
        setLinks(linkarray);
        // console.log("Customer props data : ",all?.Links[1]);
        setCustomer(customerdata);
    },[])

    let display = null;
    if(customer)
    {
        display = customer?.pageDescription?.content?.map((info,id)=>{
            // console.log("customer : ",info);
            return(
                <Col xs="12" md="6" lg="4" key={info+id}>
                    <div className={classes.image+" mt-3"}>
                        {documentToReactComponents(info,options)}
                    </div>  
                </Col>
            )
        }) 
        // display=(<div>{documentToReactComponents(operate?.pageDescription,options)}</div>)
    }
    
    
    return(
        <div className={classes.Customer}>
            <TopSection image={customer?.Image} link={links} />
            <Row>
                <Col xs="2">
                </Col>
                <Col xs="8" className="mt-4">
                    <div className={classes.breadcrumb}>
                       <NavLink to="/">HomePage</NavLink>&gt; <span className={classes.bread}></span> {customer?.title}
                    </div>
                    <div className={classes.title+" mt-3"}>
                        {customer?.title}
                    </div>
                    <div className={classes.description+" mt-4"}>
                        <Row>
                            {display} 
                        </Row>    
                    </div>
                </Col>
                <Col xs="2">
                </Col>
            </Row>
            
        </div>
    )
}

export default Customer;