import React,{useEffect,useState} from 'react';
import classes from './WhereWeOperate.module.css';
import TopSection from '../TopSection/TopSection';
import {useSelector} from 'react-redux';
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {Col,Row} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {OperateApi} from '../../Services/OperateService';

const WhereWeOperate = (props) => {

    const options = {
        renderNode: {
           
        // [INLINES.EMBEDDED_ASSET_BLOCK]: (node, children) => {
        //     console.log("Image : ",node.data.target.fields.file.url)
        //     return (<div><img src={node.data.target.fields.file.url} alt="No Image found" /></div>)
            
        //   }

        // 'embedded-asset-block': (node) =>`<img class="img-fluid" src="${node.data.target.fields.file.url}"/>`

        [BLOCKS.EMBEDDED_ASSET]: (node, children) => { // render the EMBEDDED_ASSET as you need
         return ( <img src={`https://${node?.data?.target?.fields?.file?.url}`} className={classes.operateimg} height={node?.data?.target?.fields?.file?.details?.image?.height} width={node?.data?.target?.fields?.file?.details?.image?.width} alt={node?.data?.target?.fields?.description} /> ); 
        },
        },
       
      };

    const url = useSelector(state=>state.url);
    const [operate,setOperate] = useState(null);
    const [links,setLinks] = useState(null);
    // console.log("operate : ",all);
    useEffect(async()=>{
        let operatedata=await OperateApi(url);
        // console.log("Operate data : ",operatedata);
        let linkarray = operatedata.links.map(info=>{
            return info.pageName;
        })
        linkarray.splice(0,0,operatedata.title);
        // console.log("operate props data : ",all?.Links[0]);
        setLinks(linkarray);
        setOperate(operatedata);
    },[])

    let display = null;
    if(operate)
    {
        // console.log("hello : ",operate);
        display = operate?.pageDescription?.content?.map((info,id)=>{
            // console.log("map : ",info);
            return(
                <div key={info+id}>
                    {documentToReactComponents(info,options)}
                </div>
            )
        }) 
    }
    
    return(
        <div className={classes.WhereWeOperate}>
            <TopSection image={operate?.Image} link={links} />
            <Row>
                <Col xs="2">
                </Col>
                <Col xs="8" className="mt-4">
                    <div className={classes.breadcrumb}>
                       <NavLink to="/">HomePage</NavLink> <span className={classes.bread}></span>&gt; {operate?.title}
                    </div>
                    <div className={classes.title+" mt-3"}>
                        {operate?.title}
                    </div>
                    <div className={classes.description+" mt-4"}>
                        {display}
                    </div>
                </Col>
                <Col xs="2">
                </Col>
            </Row>
            
        </div>
    )
}

export default WhereWeOperate;