import axios from 'axios';
import {printLogs,logtype} from './LogService';

export const ContactApi = async (url) => {
    try{
        var response = await axios.get(url+"/getPage/sghContact");
        printLogs("Services->ContactApi->response : ",logtype.Debug);
        printLogs(response,logtype.Debug);
        printLogs("Services->ContactApi->Fetched successful",logtype.Info);
    }
    catch(error)
    {
        printLogs("Services->ContactApi->Contact data unsuccessful -> Error",logtype.Error);
        printLogs(error,logtype.Error);
    }
    return response?.data;
}