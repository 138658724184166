import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header';
import Home from './Components/Home/Home';
import {Switch,Route} from 'react-router-dom';
import WhereWeOperate from './Components/WhereWeOperate/WhereWeOperate';
import Customer from './Components/Customer/Customer';
import Contact from './Components/Contact/Contact';

const App = () => {

  // http://localhost:4000/getPage/sgh
  // http://localhost:4000/getPage/sghContact
  // http://localhost:4000/getPage/sghHeader

  return ( 
    <div>
      <Header />
        <Switch>
          <Route path="/Customer" component={Customer} />
          <Route path="/WhereWeOperate" component={WhereWeOperate} />
          <Route path="/Contact" component={Contact} />
          <Route path="/" component={Home} />
        </Switch>
    </div>
  );
}

export default App;
