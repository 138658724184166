import React,{useEffect,useState} from 'react';
import classes from './Contact.module.css';
import TopSection from '../TopSection/TopSection';
import {useSelector} from 'react-redux';
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import {Col,Row} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {ContactApi} from '../../Services/ContactService';

const Contact = (props) => {

    const options = {
        renderNode: {
           
        // [INLINES.EMBEDDED_ASSET_BLOCK]: (node, children) => {
        //     console.log("Image : ",node.data.target.fields.file.url)
        //     return (<div><img src={node.data.target.fields.file.url} alt="No Image found" /></div>)
                
        
        //   }


        // 'embedded-asset-block': (node) =>`<img class="img-fluid" src="${node.data.target.fields.file.url}"/>`

        [BLOCKS.EMBEDDED_ASSET]: (node, children) => { // render the EMBEDDED_ASSET as you need
         return ( <img src={`https://${node?.data?.target?.fields?.file?.url}`} height={node?.data?.target?.fields?.file?.details?.image?.height} width={node?.data?.target?.fields?.file?.details?.image?.width} alt={node?.data?.target?.fields?.description} /> ); 
        },
        },
       
      };

    // const all = useSelector(state=>state.home);
    const url = useSelector(state=>state.url);
    const [contact,setContact] = useState(null);
    const [links,setLinks] = useState(null);
    // console.log("operate : ",all);
    useEffect(async ()=>{
        let contactdata = await ContactApi(url);
        // console.log("Contact Data : ",contactdata);
        // console.log("Customer data : ",all?.Links[1]);
        let linkarray = contactdata.links.map(info=>{
            return info.pageName;
        })
        linkarray.splice(2,0,contactdata.title);
        // console.log("operate props data : ",all?.Links[0]);
        setLinks(linkarray);
        setContact(contactdata);
    },[])

    let display = null;
    if(contact)
    {
        // console.log("contact : ",contact);
        display = contact?.pageDescription?.content?.map((info,id)=>{
            // console.log("content : ",info);
            return(
                <div key={info+id}>
                    {documentToReactComponents(info,options)}
                </div>
            )
        }) 
        // display=(<div>{documentToReactComponents(operate?.pageDescription,options)}</div>)
    }

    let displaycontactTitle = null;
    let displaycontactDetail= null;
    let displayadhoc=null;
    if(contact)
    {
        displaycontactTitle = contact?.contactDetail.map((info,id)=>{
            // console.log("content : ",info.fields.title);
            return(
                <Col key={info+id} className="mb-3">
                    <div className={classes.contacttitle}>
                        {info.fields.title}
                        
                    </div>
                </Col>
            )
        });
        displaycontactDetail = contact?.contactDetail.map((info,id)=>{
            // console.log("content : ",info.fields.title);
            if(id==0)
            {
                return(
                    <Col key={info+id} className="mb-3">
                        <div className={classes.contacttitle}>
                            {documentToReactComponents(info.fields.details,options)}
                        </div>
                    </Col>
                )
            }
            return(
                <Col key={info+id} className="mb-3">
                    <div className={classes.contactdesc}>
                        {documentToReactComponents(info.fields.details,options)}
                    </div>
                </Col>
            )
        });

        displayadhoc = contact?.contactDetail.map((info,id)=>{
            // console.log("content : ",info.fields.title);
            if(id==0)
            {
                return(
                    <Col key={info+id} className="mb-3">
                        <div className={classes.contacttitle}>
                            {documentToReactComponents(info.fields.adhocHandlingRequest,options)}
                        </div>
                    </Col>
                )
            }
            return(
                <Col key={info+id} className="mb-3">
                    <div className={classes.contactdesc}>
                        {documentToReactComponents(info.fields.adhocHandlingRequest,options)}
                    </div>
                </Col>
            )
        });
    }
    
    
    return(
        <div className={classes.Contact}>
            <TopSection image={contact?.Image} link={links} />
            <Row>
                <Col xs="2">
                </Col>
                <Col xs="8" className="mt-4">
                    <div className={classes.breadcrumb}>
                       <NavLink to="/">HomePage</NavLink> <span className={classes.bread}></span>&gt; {contact?.title}
                    </div>
                    <div className={classes.title+" mt-3"}>
                        {contact?.title}
                    </div>
                    <div className={classes.condescription}>
                        <Row className="mt-4">{displaycontactTitle}</Row>
                        <Row className="mt-1">{displaycontactDetail}</Row>
                        <Row className="mt-5">{displayadhoc}</Row>
                    </div>
                    <div className={classes.description+" mt-4"}>
                        {display}
                    </div>
                </Col>
                <Col xs="2">
                </Col>
            </Row>
            
        </div>
    )
}

export default Contact;